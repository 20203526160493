.Footer{
	padding: 0 0 var(--margin-2) 0;
	text-align: center;
}

.Footer-brand{
	font-weight: var(--bold);
}

.Footer-brand span{
	font-weight: var(--normal);
}