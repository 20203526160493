.desktop-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

html, body {
    margin: 0;
    padding: 0;
}
