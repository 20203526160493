.Nav{
	display: flex;
	justify-content: space-between;
	padding: 2rem 0;
	width: 100%;
}

.Nav-brand{
	font-weight: var(--bold);
	font-size: var(--h4);
}

.Nav-menu{
	position: fixed;
	top: 0;
	right: -100%;
	width: 100vw;
	height: 100vh;
	transition: .3s transform cubic-bezier(.5, .5, .53, .57);
}

.Nav-menu[data-show="true"]{
	transform: translateX(-100%);
}

.Nav-menu-wrapper{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 1rem;
	margin-left: auto;
	width: 80%;
	height: 100%;
	background-color: var(--primary);
	transition: .2s box-shadow ease;
}

.Nav-menu[data-show="true"] .Nav-menu-wrapper{
	box-shadow: -20vw 0px 30px -1px #00000033;
}

.Nav-link{
	font-weight: var(--normal);
}

.Nav-link span{
	font-weight: var(--light);
}

.Nav-buttons{
	display: flex;
	gap: .5rem;
	z-index: 3;
}

.Nav-switch{
	font-size: 1.5rem;
	transition: .2s ease;
}

.Nav-switch:active{
	transform: translateY(-2px);
}

.Nav-toggle{
	font-size: 2rem;
	transition: .2s ease;
}

.Nav-toggle:active{
	transform: translateY(-2px);
}

@media (min-width: 576px) {
	.Nav-menu{
		position: static;
		height: auto;
	}

	.Nav-menu-wrapper{
		flex-direction: row;
		justify-content: end;
		gap: 2.5rem;
		padding-right: 2rem;
	}

	.Nav-link{
		position: relative;
		overflow: hidden;
	}

	.Nav-link::before{
		content: '';
		position: absolute;
		left: -100%;
		bottom: 0;
		width: 100%;
		height: 3px;
		background-color: var(--accent);
		transition: .2s ease;
	}

	.Nav-link:hover::before{
		transform: translateX(100%);
	}

	.Nav-toggle{
		display: none;
	}
}