.Home.section {
    padding: var(--margin-2) 0;
  }
  
  .Home > .container-sm {
    padding: 0 1rem;
  }
  
  .Home-hi {
    color: var(--accent);
    margin-left: .3rem;
  }
  
  .Home-h1 {
    color: var(--accent);
    font-weight: var(--bold);
    font-size: var(--big-head);
    line-height: 1;
  }
  
  .Home-h2 {
    color: var(--accent);
    font-size: var(--big-sub-head);
    margin-bottom: var(--margin-4);
  }
  
  .Home-description {
    max-width: 520px;
  }
  
  .Home-resume {
    margin-top: var(--margin-5);
    margin-bottom: var(--margin-4);
    font-style: italic;
  }
  
  .Home-resume:hover {
    text-decoration: underline;
    color: var(--accent);
  }
  
  .power-button {
    display: inline-block;
    width: 100px;
    height: 100px;
    background-color: #f44336;
    border-radius: 50%;
    border: none;
    color: white;
    font-size: 48px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .power-button:hover {
    transform: scale(1.1);
  }
  
  .power-button.on {
    background-color: #4caf50;
  }
  
  .power-button i {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .power-button:focus {
    outline: none;
  }
  
  .typewriter {
    overflow: hidden;
    border-right: .15em solid orange;
    white-space: nowrap;
    animation: 
      typing 3.75s steps(40, end),
      blink-caret .75s step-end infinite;
  }
  
  .typewriter.typing-complete {
    border-right: none;
    animation: typing 3.75s steps(40, end);
  }
  
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange; }
  } 
  
  