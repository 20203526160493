.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
}

.loading-icon {
    position: relative;
    width: 500px;
}

.progress-bar {
    position: relative;
    bottom: -20px;
    width: 450px;
    height: 5px;
    background-color: #ddd;
    overflow: hidden;
}

.progress {
    height: 100%;
    width: 0;
    background-color: #556B2F;
    animation: fillProgress 2s linear forwards;
}

@keyframes fillProgress {
    from { width: 0; }
    to { width: 100%; }
}
