.desktop-icon {
    cursor: pointer;
    text-align: center;
    margin: 10px;
    user-select: none;
    max-height: 100px;
    max-width: 100px;
}

.desktop-icon.active {
    background-color: #cccccc; 
    border-radius: 5px;
}

.desktop-icon img {
    width: 50px;
    height: 50px;
    pointer-events: none;
}

.desktop-icon span {
    display: block;
    margin-top: 5px;
    color: white;
}

