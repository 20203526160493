.ScrollToTop {
	position: fixed;
	right: var(--margin-4);
	bottom: 0;
	padding: 0.3rem;
	margin: var(--margin-3) 0;
	border: 3px solid var(--accent);
	border-radius: 15px;
	color: var(--accent);
	font-size: 1.5rem;
	line-height: 0;
	opacity: 0;
	visibility: hidden;
	transition: 0.2s ease;
}

.ScrollToTop[data-show='true'] {
	visibility: visible;
	opacity: 0.8;
}

@media (min-width: 1920px) {
	.ScrollToTop {
		display: none;
	}
}
