.Button {
	color: var(--primary);
	background-color: var(--accent);
	font-weight: var(--bold);
}

.Button-link {
	padding: 0.8rem 2.2rem;
}

.Button-text {
	display: inline-block;
	position: relative;
	color: var(--primary);
	line-height: initial;
	overflow: hidden;
}

.Button-text::before {
	content: '';
	position: absolute;
	bottom: 0;
	left: -100%;
	width: 100%;
	height: 2px;
	background-color: var(--primary);
	transition: 0.2s ease;
}

.Button:hover .Button-text::before {
	transform: translateX(100%);
}
