*, ::before, ::after{
	margin: 0;
	padding: 0;
	border: 0;
	box-sizing: border-box;
}

.hero{
    color: var(--accent);
    background-color: var(--primary);
}

html{
	font-size: 100%;
	scroll-behavior: smooth;
}

body{
	font-family: var(--font), sans-serif;
  line-height: 1.75;
}

body.disable-scroll{
	overflow-y: hidden;
}

p{
	margin-bottom: 1rem;
	font-weight: var(--light);
}

h1, h2, h3, h4, h5{
  font-weight: var(--normal);
  line-height: 1.3;
}

h1{
  margin-top: 0;
  font-size: var(--h1);
}

h2{
	font-size: var(--h2);
}

h3{
	font-size: var(--h3);
}

h4{
	font-size: var(--h4);
}

small, .text_small{
	font-size: var(--text-small);
}

a{
	display: inline-block;
	color: var(--accent);
	text-decoration: none;
}

ul{
	list-style: none;
}

.hero button{
	color: var(--accent);
	background: none;
	cursor: pointer;
	line-height: 0;
}

.hero .container{
	margin: 0 auto;
	max-width: 860px;
}

.hero .section{
	padding: var(--margin-2) 0;
}

.hero .section-head{
	margin-bottom: var(--margin-1);
	font-weight: var(--bold);
}
