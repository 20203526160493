.Contact .container-sm{
	text-align: center;
}

.Contact-h3{
	margin-bottom: var(--margin-3);
}

.Contact-h3 a{
	border-bottom: 3px solid var(--accent);
}

.Contact-description{
	margin: 0 auto var(--margin-3) auto;
	max-width: 540px;
}

.Contact-button{
	margin-bottom: var(--margin-2);
}

.Contact-social{
	display: flex;
	justify-content: center;
}