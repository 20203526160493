.Card{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 1.5rem;
	width: clamp(320px, 100%, 400px);
	aspect-ratio: 1 / 1;
	border: 5px solid var(--accent);
	border-radius: 50px;
}

.Card-h3{
	margin-bottom: var(--margin-3);
}

.Card-tech{
	display: flex;
	gap: .5rem;
	font-size: 1.3rem;
	margin-bottom: var(--margin-3);
}

.Card-links{
	display: flex;
	justify-content: space-between;
}

.Card-link-project{
	position: relative;
	font-size: var(--h4);
	font-weight: var(--bold);
	overflow: hidden;
}

.Card-link-project::before{
	content: '';
	position: absolute;
	bottom: 0px;
	height: 4px;
	width: 100%;
	background-color: var(--accent);
	transition: .2s ease;
}

.Card-link-project:hover::before{
	transform: translateX(-100%);
}

.Card-icon-arrow {
	font-size: 1.4rem;
	transform: rotate(-45deg);
}

.Card-link-github{
	line-height: 0;
}

.Card-icon-github{
	font-size: 2.1rem;
}

.Card-icon-github:hover{
	transform: scale(1.1);
}

@media (min-width: 576px) {
	.Card{
		padding: 3rem;
	}
	.Card-h3{
		line-height: 0;
	}
}