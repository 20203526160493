/* .desktop-icons {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    user-select: none;
    height: 95%;
    z-index: 50;
} */

.desktop {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    background-color: #282c34;
    overflow: hidden;
}

.taskbar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #a8a8a881;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    z-index: 100;
}

.taskbar .terminal-button {
    background: url('/public/terminal.png') no-repeat center center;
    background-size: contain;
}

.taskbar .test-button {
    background: url('/public/notepad.png') no-repeat center center;
    background-size: contain;
}

.taskbar .resume-button {
    background: url('/public/CV.webp') no-repeat center center;
    background-size: contain;
}

.taskbar .projects-button {
    background: url('/public/project.png') no-repeat center center;
    background-size: contain;

}

.taskbar button {
    width: 40px;
    height: 40px;
    border-width: 0;
}

.taskbar button:hover {
    background-color: white;
}

.taskbar .app-icons {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    gap: 20px;
}

.system-tray {
    display: flex;
    justify-content: flex-end;
    padding: 0 0px;
    font-family: 'Consolas', monospace;
    /* other styles */
}

.system-tray div {
    text-align: right;
    line-height: 1.2;
    color: rgb(0, 0, 0);
    font-size: 16px; 
    padding: 30px 30px;
}

.taskbar-button.highlighted {
    background-color: white;
}

.taskbar-button.active{
    border-bottom:3px solid rgb(104, 104, 104);
}
