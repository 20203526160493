.terminal {
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-width: 100%;
    max-height: 90vh;
    min-width: 300px;
    min-height: 200px;

    width: 100%;
    height: 100%;
    overflow: hidden; 

    background-color: black;
    color: limegreen;
    font-family: 'Consolas', monospace;
    height: 90vh;
    border-radius: 10px;
}

.terminal-container {
    z-index: 100;
    position: relative;
}

.terminal-rnd {
    overflow: hidden;
    border-radius: 10px;
    /* display: flex;
    flex-direction: column;
    height: 100%; */
}

.terminal-top-bar {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #333;
    color: white;
    padding: 5px 20px;
    display: flex;
    justify-content: flex-end;
    user-select: none;
}

.drag-handle {
    cursor: move;
}

.terminal-top-bar button {
    background-color: #444;
    border: none;
    color: white;
    margin-left: 5px;
    cursor: pointer;
}

.terminal-body {
    /* flex-grow: 1; */
    margin-left: 20px;
    overflow-y: auto;
    height: calc(100%);
}

.terminal-input-container {
    margin-top: 10px;
    position: relative;
    display: flex;
    align-items: center;
}

.terminal-input-form {
    display: flex;
    flex-grow: 1;
}

.terminal-input {
    background-color: black;
    color: limegreen;
    border: none;
    outline: none;
    font-family: 'Consolas', monospace;
    font-size: 16px;
    flex-grow: 1;
}

.autocomplete-suggestion {
    position: absolute;
    margin-top: 1px;
    left: 0;
    margin-left: calc(2ch);
    color: #555;
    font-family: 'Consolas', monospace;
    font-size: 16px;
    pointer-events: none;
}

.prompt {
    color: limegreen;
    margin-right: 5px;
}

/* .terminal-input::after {
    content: '|';
    animation: blink 1s step-start infinite;
}

@keyframes blink {
    50% { opacity: 0; }
} */

.terminal-output {
    margin-top: 10px;
}

.command {
    font-weight: bold;
}

.output {
    margin-left: 20px;
}

/* Custom scrollbar styles */
.terminal-body::-webkit-scrollbar {
    width: 8px;
}

.terminal-body::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.terminal-body::-webkit-scrollbar-thumb {
    background-color: #888;
}

.terminal-body::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}


.terminal-container.hidden {
    display: none;
}

.terminal-output-container {
    flex: 1;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 #ffffff00;
}

.terminal-output-container::-webkit-scrollbar {
    width: 8px;
}

.terminal-output-container::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.terminal-output-container::-webkit-scrollbar-thumb {
    background-color: #888;
}

.terminal-output-container::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}